import React, {useEffect} from "react";
import {graphql} from "gatsby";

import SiteLayout from "components/layouts/SiteLayout";
import {ROUTES_PL} from "config";

export default function LaboratoryTests() {
  useEffect(() => {
    window.location.href = ROUTES_PL.testPrepare;
  }, []);

  return (
    <SiteLayout />
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["index"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
